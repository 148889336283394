$(document).on('click', '.js_get-bus', getBus);
$(document).on('click', '.js_tour-disable-order', TourDisableOrder);
$(document).on('click', '.seat:not(.reserved)', clickSeat);
$(document).on('change', '.js_orders-count_places', clickSeat);
$(document).on('click', '.js_form-places-btn', continueOrder);
$(document).on('form-ajax', '.js_form-places', eventPlacesForm);
$(document).on('click', '.js_get-detail', getDetail);
$(document).on('click', '.nf-route-detail-tabs-controls__item', tourTabs);
$(document).on('click', '.js_get-bus-row-bus > .seats-choice', getBus);

function TourDisableOrder() {
    $('html, body').animate({
        scrollTop: $(".blocksWrapper").offset().top
    }, 500);
    let time = $(this).data('time');
    toastr.warning('Бронирование на рейс за ' + time + ' мин до отправления недоступно через сайт, Вы можете забронировать билет с помощью оператора. Благодарим за понимание');
}

function eventPlacesForm(e, response) {
    let form = $(e.target);

    if (response.view) {
        let rowBus = form.closest('.js_get-bus-row-bus');
        rowBus.attr('data-scroll', rowBus.find('.js_bus-wrap').scrollLeft());
        rowBus.html(response.view);
        rowBus.find('.js_bus-wrap').scrollLeft(rowBus.attr('data-scroll'));
    }
    form.closest('.js_get-bus-row').find('.js_bus-overlay').hide();
}

function getBus(e) {
    let self = $(this),
        button = $(e.target);

    if (!self.hasClass('disabled')) {
        let url = self.data('url'),
            returnData = self.data('return'),
            placesData = self.data('places'),
            $wrapper = self.closest('.js_route').find('.js_get-bus-row'),
            $activeWrapper = self.closest('.nf-routes').find('.js_get-bus-row.active');

        if (!$wrapper.hasClass('active')) {
            $activeWrapper.removeClass('active').hide();
            $.post(url, { return_ticket: returnData, places: placesData }, function (response) {
                if (response.result == 'success') {
                    $wrapper
                        .show()
                        .addClass('active');
                    $wrapper.find('.js_get-bus-row-bus').html(response.view);
                    if ( $wrapper.find('.js_orders-count_places').length && ( window.innerWidth < 992 ) ) {
                        button.attr('disabled', 'disabled');
                        $wrapper.hide();
                        $wrapper.find('.js_form-places-btn').trigger('click');
                    }
                } else {
                    toastr.error(response.message);
                }
            });
        } else {
            $wrapper.removeClass('active').hide();
        }
    }
    return false;
}

function continueOrder() {
    if ($('#return_flag > option:selected').val() == 1) {
        let returnBlockName = '.scheduleBlockReturn';
        let places = $('.scheduleBlock .js_get-bus-row-bus .cell.active:visible').length + parseInt($('.scheduleBlock .js_orders-count_places').val() || 0);  // Кол-во мест туда
        let places2 = $('.scheduleBlockReturn .js_get-bus-row-bus .cell.active:visible').length + parseInt($('.scheduleBlockReturn .js_orders-count_places').val() || 0); // Кол-во мест обратно
        if (places !== places2) {
            toastr.error('Количество мест туда и обратно должны совпадать!');
            return false;
        }
        if (places == 0) {
            toastr.error('Выберите места!');
            return false;
        }
    }
    clickSeat(true, $(this));
}

function clickSeat(order, $this) {
    $this = $this ? $this : $(this)
    let $wrapper = $this.closest('.js_get-bus-row');
    let $form = $wrapper.find('.js_form-places');
    let $wrapperInput = $form.find('.js_form-places-inputs')
    $wrapper.find('.js_bus-overlay').show()
    $wrapperInput.html('');
    $wrapperInput.prepend('<input type="hidden" name="return_ticket" value="' + $this.closest('.shedulePage').find('.reservationButton').eq(0).data('return') + '"/>');
    var $countPlaces = $wrapper.find('.js_orders-count_places');

    if ($countPlaces.length) {
        let val = $countPlaces.val();
        for (let $i = 0; $i < val; $i++) {
            $wrapperInput.prepend(`<input type="hidden" name="places[]" value=""/>`);
        }
    } else {
        if (parseInt(cnt_reserved_places_tour) >= parseInt(limit_order_by_place) &&
            $(this).hasClass('active') !== true && order !== true
        ) {
            toastr.error("Бронирование ограничено");
        } else $(this).toggleClass('active');

        $wrapper.find('.seat.active:not(.reserved)').each(function () {
            let number = $(this).data('number');
            $wrapperInput.prepend(`<input type="hidden" name="places[]" value="${number}"/>`);
        })
    }
    if (order === true) $form.addClass('js_form-step-order')
    $form.submit();
    return false;
}

function getDetail() {
    let detailBlock = $(this).closest('.nf-route').find('.nf-route-detail');

    detailBlock.find('.nf-route-detail-tabs-controls__item:first-child').trigger('click');
    detailBlock.slideToggle();
}

function tourTabs() {
    let index = $(this).index();

    $(this).addClass('is-active').siblings('.is-active').removeClass('is-active');
    $(this).closest('.nf-route-detail').find('.nf-route-detail-tabs-container-item').eq(index).addClass('is-show').siblings('.is-show').removeClass('is-show');
}



